<template>
  <v-row>
    <v-col cols="12" sm="3" />
    <v-col cols="12" sm="6">
      <v-card ref="form">
        <v-card-title> Signup to Torchship </v-card-title>
        <v-card-subtitle>
          Joining Torchship will grant access to the community forums and
          character manager.
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="username"
            :rules="[rules.max_username]"
            counter="25"
            label="Account Username"
            hint="This is the name used when out of character or posting on the boards."
            outlined
            clearable
            dense
          />
          <v-text-field
            v-model="email"
            :rules="[rules.valid_email]"
            label="Email"
            hint="Used for sending passwords, never spam."
            outlined
            clearable
            dense
          />
          <v-text-field
            label="Password"
            :rules="[rules.min_password]"
            v-model="password"
            hint="The password that will be used to log in to the website."
            outlined
            clearable
            dense
            type="password"
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
          />
          <v-text-field
            label="Repeat Password"
            v-model="confirm_password"
            :rules="[rules.min_password]"
            hint="Type again to verify it's correct!"
            outlined
            clearable
            dense
            type="password"
          />
          <v-checkbox
            v-model="aup"
            label="I agree to abide by the rules."
          ></v-checkbox>
          <v-checkbox
            v-model="of_age"
            label="I certify that I am at least 18 years old, or the age of majority where I reside."
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="submit_registration"
            :disabled="can_join"
            >Join Now</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import store from "@/store";

import { REGISTER, LOGIN } from "@/store/actions.type";
import { BEGIN_LOADING } from "@/store/mutations.type";

export default Vue.extend({
  name: "Signup",
  computed: {
    can_join: function () {
      return !(
        this.aup &&
        this.of_age &&
        this.username &&
        this.password &&
        this.confirm_password &&
        this.password == this.confirm_password
      );
    },
  },
  methods: {
    submit_registration: function () {
      store.commit(BEGIN_LOADING);
      store
        .dispatch(REGISTER, {
          email: this.email,
          password: this.password,
          username: this.username,
        })
        .then(() => {
          store
            .dispatch(LOGIN, {
              user: this.email,
              password: this.password,
            })
            .then(() => {
              this.$router.back();
            });
        });
    },
  },
  data: () => ({
    show_password: false,
    password: "",
    confirm_password: "",
    username: "",
    email: "",
    of_age: false,
    aup: false,
    rules: {
      max_username: (v) => v.length <= 25 || "Max 25 characters",
      min_password: (v) => v.length >= 5 || "Min 5 characters",
      valid_email: (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    },
  }),
});
</script>
